@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7a7f9d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #21295c;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #21295c;
}

.CSR_questionare {
  display: block;
  padding-right: 50px;
}

.CSR_questionare li {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: solid 1px #ddd;
}
.CSR_questionare li:first-child {
  border-bottom:solid 2px #ddd;
  padding-bottom: 10px;
}
.CSR_questionare li div {
  display: flex;
  gap: 20px;
  align-items: center;
}
.CSR_questionare li div > p {
  width: 150px;
}
.CSR_questionare li div > label {
  width: 70px;
}
.CSR_questionare li div > span {
  width: 110px;
}